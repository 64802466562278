@import '../../styles/index';

.base {
  margin: 0;

  p {
    font-size: $font-size-22;
    font-weight: $font-weight-light;
    margin-bottom: 0.75rem;
    margin-top: 0;
  }
}

.top-label {
  font-weight: $font-weight-medium;
  font-size: $font-size-22;
  margin-bottom: 1rem;
}

.info-icon {
  color: $surrogate-9;
  vertical-align: middle;
}
